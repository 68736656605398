.mobile {
  display: none;
}

.subheading5 {
  color: white;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  text-decoration: underline;
  text-underline-offset: 3px;
  padding-bottom: 0.5rem;
}

.row {
  width: 100%;
  margin-top: 15%;
  position: static;
  display: flex;
}
.column1 {
  text-align: left;
  flex: 70%;
  padding: 2% 0 0 4%;
  background-color: rgba(0, 0, 0, 255);
}
.column2 {
  text-align: left;
  flex: 30%;
  margin-left: -1%;
  padding: 2% 0 0 4%;
  background-color: rgba(0, 0, 0, 255);
}

.heading {
  color: white;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}
.subheading {
  color: white;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  padding-top: 10px;
}


.icons {
  /*padding: 0 0.5rem 0 0.5rem;*/
  color:white;
  font-weight: bold;
  font-size: x-large;
}

.inline {
  display: flex;
  align-items: flex-start; 
}

.sponsorsFooter  {
    background: #000000;
    padding: 0px 0 0px;
    margin-bottom: 30px;
}



:before,:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}



.containerFooter {
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
}



@media (min-width: 768px){
  .containerFooter {
    width: 750px;
  }
}



@media (min-width: 992px){
  .containerFooter {
    width: 700px;
  }
}



@media (min-width: 1460px){
  .containerFooter {
    width: 1020px;
  }
}



.clearfix:before,.clearfix:after,dd:before ,dd:after ,.containerFooter:before,.containerFooter:after,.containerFooter-fluid:before,.containerFooter-fluid:after,.row:before,.row:after,.form-group:before ,.form-group:after ,.btn-toolbar:before,.btn-toolbar:after,.btn-group-vertical > .btn-group:before ,.btn-group-vertical > .btn-group:after ,.nav:before,.nav:after,.navbar:before,.navbar:after,.navbar-header:before,.navbar-header:after,.navbar-collapse:before,.navbar-collapse:after,.pager:before,.pager:after,.panel-body:before,.panel-body:after,.modal-header:before,.modal-header:after,.modal-footer:before,.modal-footer:after {
    display: table;
    content: " ";
}



.clearfix:after,dd:after ,.containerFooter:after,.containerFooter-fluid:after,.row:after,.form-group:after ,.btn-toolbar:after,.btn-group-vertical > .btn-group:after ,.nav:after,.navbar:after,.navbar-header:after,.navbar-collapse:after,.pager:after,.panel-body:after,.modal-header:after,.modal-footer:after {
    clear: both;
}



.group  {
    float: left;
    margin: 0 30px 20px 0;
}



.group.clear  {
    clear: left;
}



p {
    margin: 0 0 10px;
}



.group .title  {
    color: #FFFFFF;
    margin-bottom: 10px;
    font-size: 12px;
    white-space: nowrap;
}



.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}



.group .table  {
    display: table;
}



.group .table .cell  {
    display: table-cell;
    padding-right: 30px;
}



a {
    background-color:  transparent;
    color: #337ab7;
    text-decoration: none;
}



.group .table .cell a  {
    opacity: 1;
    -moz-opacity: 1;
    filter: alpha(opacity=100);
}



a:active,a:hover {
    outline: 0;
}



a:hover {
    color: #23527c;
    text-decoration: underline;
}



.group .table .cell a:hover  {
    opacity: 1;
    -moz-opacity: 1;
    filter: alpha(opacity=100);
}



img {
    border:  0;
    vertical-align: middle;
}



@media (max-width: 767px){
  footer {
    padding: 20px 0;
  }
}



@media (max-width: 600px){
  .group  {
    float: none;
    margin: 0;
  }



  .group .table  {
    display: block;
    margin-bottom: 10px;
  }



  .group .table .cell  {
    display: inline-block;
    height: 40px;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
    margin-top: 40%;
  }
  .rowm {
    width: 100%;
    margin-top: 30%;
    position: static;
    display: block;

    margin-bottom: -5%;
  }
  .rowm1 {
    width: 100%;
    position: static;
    display: block;

    margin-bottom: -2%;
  }
  .rowm2 {
    width: 100%;
    position: static;
    display: block;

    margin-bottom: -2%;
  }
  .rowm3 {
    width: 100%;
    position: static;
    display: block;

    margin-bottom: -2%;
  }
  .rowm4 {
    width: 100%;
    position: static;
    display: block;
    margin-bottom: -2%;
  }
  .rowm5 {
    width: 100%;
    position: static;
    display: block;
    margin-bottom: -2%;
  }
  .rowm6 {
    width: 100%;
    position: static;
    display: block;
  }

  .columnm1 {
    text-align: left;
    padding-top: 5%;
    padding-left: 4%;
    background-color: rgba(0, 0, 0, 255);
  }
  .columnm2 {
    text-align: left;
    padding-top: 10%;
    padding-left: 4%;
    background-color: rgba(0, 0, 0, 255);
  }
  .columnm3 {
    text-align: left;
    padding-top: 5%;
    padding-left: 4%;
    background-color: rgba(0, 0, 0, 255);
  }
  .columnm4 {
    text-align: left;
    padding-top: 5%;
    padding-left: 4%;
    background-color: rgba(0, 0, 0, 255);
  }
  .columnm5 {
    text-align: left;
    padding-top: 5%;
    padding-left: 4%;
    background-color: rgba(0, 0, 0, 255);
  }
  .columnm6 {
    text-align: left;
    padding-top: 5%;
    padding-left: 4%;
    background-color: rgba(0, 0, 0, 255);
  }
  .columnm7 {
    text-align: left;
    padding-top: 5%;
    padding-left: 4%;
    padding-bottom: 5%;
    background-color: rgba(0, 0, 0, 255);
  }
  .subheadingm {
    color: white;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
  }
  .subheadingm2 {
    color: white;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    padding-bottom: 10px;
  }
  .subheadingm3 {
    color: white;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    padding-bottom: 10px;
  }
  .subheadingm5 {
    color: white;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
  .mainspons {
    width: 90%;
  }
  .exclspons {
    width: 60%;
  }
  .festpart {
    width: 40%;
  }
  .supportmob {
    width: 50%;
  }
  .patronsmob {
    width: 100%;
  }
  .socialMediam{
    margin-top: 5px;
  }
}