@import url("https://fonts.googleapis.com/css?family=Open+Sans");

.btn {
  vertical-align: middle;
  background-color: rgba(208, 19, 23, 255);
  margin-top: -15%;
  margin-bottom: -20%;
  padding: 1% 4% 1% 4%;
  font-size: 1.6vw;
  color: white;
  border-radius: 12px;
  border-color: rgba(208, 19, 23, 255);
  font-family: "Open Sans", sans-serif;
}
.btn:hover {
  background-color: rgba(255, 19, 23, 255);
}
.img {
  margin-top: 7%;
  width: 25%;
  margin-bottom: 10%;
}
.textinput {
  padding: 5px;
  border-radius: 12px;
}
.textinput:focus {
  box-shadow: 0 0 0 2px rgba(255, 19, 23, 255);
}
.textinput:hover {
  box-shadow: 0 0 0 2px rgba(255, 19, 23, 255);
}
.textinput:active {
  box-shadow: 0 0 0 2px rgba(255, 19, 23, 255);
}
.invite {
  font-size: 2vw;
  margin-top: -8%;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}
.error-msg {
  font-size: 1.5vw;
  font-family: "Open Sans", sans-serif;
  background-color: #ffbaba;
  padding: 5px;
  border-radius: 12px;
  width: 50%;
}
.errorContainer{
  margin-top: 2em;
  display: flex;
  justify-content: center;

}

@media only screen and (max-width: 767px) {
  .btn {
    background-color: rgba(208, 19, 23, 255);
    vertical-align: middle;
    font-family: "Open Sans", sans-serif;
    margin-top: -8%;
    color: white;
    font-size: 3.5vw;
    border-radius: 10px;
    padding: 3% 7% 3% 7%;
  }
  .btn:focus {
    background-color: rgba(255, 19, 23, 255);
  }
  .img {
    margin-top: 25%;
    width: 60%;
    margin-left: -10%;
    margin-bottom: 10%;
  }
  .textinput {
    padding: 5px;
    border-radius: 10px;
  }
  .textinput:focus {
    -webkit-box-shadow: 0 0 0 2px rgba(255, 19, 23, 0.2);
    box-shadow: 0 0 0 2px rgba(255, 19, 23, 0.2);
  }
  .site-form-item-icon {
    font-size: 15px;
  }
  .invite {
    font-size: 7vw;
    margin-top: -5%;
    margin-bottom: 2%;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
  }

  .error-msg {
    font-size: 3.5vw;
    font-family: "Open Sans", sans-serif;
    background-color: #ffbaba;
    margin: 10px 0;
    padding: 5px;
    border-radius: 10px;
    width: 90%;
  }
}
