.content #Rules {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: "Open Sans", sans-serif;
  font-size:22px;
}

.content #Rules h3 {
  font-style: oblique;
  font-size:18px;
}

.wrapper {
  max-width: 85em;
  margin-left: auto;
  margin-right: auto;
  float: left;
}

.responsive-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-gap: 2rem;
  padding: 2rem;
}
.responsive-grid a {
  border-radius: 20px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.1s;
}

.card1 {
  background: radial-gradient(#d70005, #d70005);
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  color: white;
  flex-wrap: wrap;
}

.card1:hover {
  color: white;
}



@media (min-width: 767px) {
  .card1:hover {
    scale: (1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

/* header */

.logo {
  width: 100%;
}

.navbar {
  align-items: center;
  display: flex;

  justify-content: space-between;
  padding: 20px;
 }

 .nav-links a {
  color: #fff;
 }

.img2{
  width: 30%;
  float: left;
}

.img3{
  width: 18%;
  margin-right: 2%;
}

.img4 {
width: 22%;
margin-right: 2%;
}

.img5{
width: 30%;
margin-right: 2%;
}

 .menu {
  display: inline;
  width: 50%;
 }

 .inlineblock{
  display: inline;
 }

 .menu li {
  list-style: none;
 }

 @media only screen and (max-width: 768px) {

  #Rules h2 {
    font-size: 1em;
  }
  .img2{
    width: 70%;
  }
  #Rules h3 {
    font-size: 0.5em;
  }
  .logo a {
    display: inline-block;
    display: flex;
    justify-content: center;
  }
  .logo {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .menu{
    display: none;
  }
}