@import url("https://fonts.googleapis.com/css?family=Open+Sans");

.btn {
  vertical-align: middle;
  background-color: rgba(208, 19, 23, 255);
  margin-top: -15%;
  margin-bottom: -20%;
  padding: 0.5% 2% 0.5% 2%;
  font-size: 1vw;
  color: white;
  border-radius: 12px;
  border-color: rgba(208, 19, 23, 255);
  font-family: "Open Sans", sans-serif;
}
.btn:hover {
  background-color: rgba(255, 19, 23, 255);
}
h1 {
  font-size: 2.5em;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}
p {
  font-size: 1.2em;
  font-family: "Open Sans", sans-serif;
}
label{
    font-size: 1.3em;
    font-family: "Open Sans", sans-serif;
}
h3 {
  font-size: 1.3em;
  font-family: "Open Sans", sans-serif;
}
.content{
    text-align: center;
    margin-top: 1em;
}

.formContainer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 2em;
}

.formRow{
    margin-bottom: 2em;
}

.buttonsContainer{
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 1.5em;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
  }
  p {
    font-size: 1em;
    font-family: "Open Sans", sans-serif;
  }
    .container{
        padding:1em;
    }
    .btn {
        background-color: rgba(208, 19, 23, 255);
        vertical-align: middle;
        font-family: "Open Sans", sans-serif;
        margin-top: -8%;
        color: white;
        font-size: 3.5vw;
        border-radius: 10px;
        padding: 3% 7% 3% 7%;
      }
      .btn:focus {
        background-color: rgba(255, 19, 23, 255);
      }
}

/* Ensure the default radio button is completely hidden */
.radio-input {
    appearance: none;
    -webkit-appearance: none; /* For older versions of Safari */
    opacity: 0;
    position: absolute;
    margin: 0;
    width: 0;
    height: 0;
  }
  
  /* Custom radio button styling */
  .radio-label {
    position: relative;
    padding-left: 35px;
    margin-right: 15px;
    cursor: pointer;
    display: inline-block;
    color: #444;
    font-size: 16px;
    line-height: 24px;
  }
  
  /* Circle behind the radio button */
  .radio-label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #f2f2f2;
    border: 2px solid #d1d3d4;
    transition: background 0.3s, border-color 0.3s;
  }
  
  /* Circle that appears when the radio button is selected */
  .radio-input:checked + .radio-label:after {
    content: '';
    position: absolute;
    left: 8px;
    top: calc(50%);
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #c52e2e;
  }
  
  /* Hover effect for the label */
  .radio-label:hover:before {
    background: #e9e9e9;
  }